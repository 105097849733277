export const certificateData = [
    {
        id: 1,
        
        course: 'Electrical Auto CAD  ',
        institution: 'CAD Centre, Adoor',
        startYear: '2022'
       
    },
    {
        id: 2,
        
        course: 'Autodesk Revit Mechanical Electrical and Plumbing (MEP) ',
        institution: 'CAD Centre, Adoor',
        startYear: '2022'
    },
    
]
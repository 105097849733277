export const experienceData = [
    {
        id: 1,
        company: 'Kerala State Electricity Board',
        jobtitle: 'Sub Engineer Transmission Construction',
        startYear: 'Jan 2023',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Kerala State Electricity Board',
        jobtitle: 'Sub Engineer Operation and Maintenance',
        startYear: 'Nov 2019',
        endYear: 'Dec 2022'
    },
    
]
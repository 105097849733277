export const contactsData = {
    email: 'akhiljp.skj@gmail.com',
    phone: '+91 99958 65518',
    whatsapp: <a href="https://wa.me/919995865518?text=Hi" target="blank">+91 99958 65518</a>,
    facebook: 'https://www.facebook.com/akhil.prakash.1460?mibextid=ZbWKwL',
    linkedIn: 'https://www.linkedin.com/in/akhil-prakash-6852721a6/',
    twitter: 'https://twitter.com/Akhil66404440?t=-mkCYN9at3nd2kTRt0ZQag&s=08',
    YOUR_SERVICE_ID: 'service_vu0q6rj',
    YOUR_TEMPLATE_ID: 'template_g248pz8',
    YOUR_USER_ID: "u702O_DaQXT3oOVJC",
}